import React, { useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import SchoolServiceAdmin from '../../services/SchoolServiceAdmin';
import 'chart.js/auto';

const AdminDisplayTeacherAttendanceCheck = ({ data }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [attendanceData, setAttendanceData] = useState([]);

  const startDateRef = useRef();
  const endDateRef = useRef();

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === 'start') {
      setStartDate(value);
    } else if (name === 'end') {
      setEndDate(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response =
        await SchoolServiceAdmin.getTeacherAttendanceCheckOnDateRange(
          startDateRef.current.value,
          endDateRef.current.value
        );
      setAttendanceData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setAttendanceData([]);
    startDateRef.current.value = '';
    endDateRef.current.value = '';
  };

  const generateChartData = (dateChecks) => {
    return {
      labels: dateChecks.map((check) => check.dateEntity.date),
      datasets: [
        {
          data: dateChecks.map(() => 1),
          backgroundColor: dateChecks.map((check) =>
            check.checked ? '#4caf50' : '#f44336'
          ),
          borderColor: '#ffffff',
          borderWidth: 2,
          hoverBorderColor: '#ffffff',
          hoverBorderWidth: 3,
        },
      ],
    };
  };

  const generateChartOptions = (dateChecks) => {
    return {
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const date = tooltipItem.label;
              const checked =
                dateChecks[tooltipItem.dataIndex].checked;
              return `${date}: ${checked ? 'Checked' : 'Unchecked'}`;
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };
  };

  return (
    <>
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
      <h1 className="md:text-2xl text-2xl text-center font-semibold text-cyan-900 py-4">
        View Teacher Attendance Records for Selected Date Range
      </h1>
      <div className="w-full flex justify-center">
        <form onSubmit={handleSubmit} className="text-center">
          <input
            ref={startDateRef}
            type="date"
            name="start"
            value={startDate}
            onChange={handleDateChange}
            className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
          <input
            ref={endDateRef}
            type="date"
            name="end"
            value={endDate}
            onChange={handleDateChange}
            className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 mt-2"
          />
          <button
            type="submit"
            className="rounded text-white px-8 py-3 mx-1 my-2 font-semibold  bg-cyan-700 hover:bg-cyan-800"
          >
            Submit
          </button>
          <button
            type="button"
            onClick={handleReset}
            className="rounded text-white px-8 py-3 mx-1 my-2 font-semibold  bg-cyan-700 hover:bg-cyan-800"
          >
            Reset
          </button>
        </form>
      </div>

      <div className="py-8">
        {attendanceData.length > 0 &&
          attendanceData.map((teacher, index) => (
            <div
              key={index}
              className="my-8 border border-gray-300 rounded-lg p-4 shadow-lg"
            >
              <h2 className="text-lg font-semibold text-cyan-900 mb-2">
                {teacher.teacherFirstName} {teacher.teacherLastName} (
                {teacher.classAbbrev})
              </h2>

              <div className="flex flex-wrap items-center justify-between">
                <div className="w-1/2 mb-4">
                  <Pie
                    data={generateChartData(teacher.dateChecks)}
                    options={generateChartOptions(teacher.dateChecks)}
                    width={150}
                    height={150}
                  />
                </div>

                <div className="w-1/2">
                  {teacher.dateChecks.map((check, idx) => (
                    <div
                      key={idx}
                      className="py-2 border-b border-gray-200"
                    >
                      <p className="text-sm font-medium">
                        Date: {check.dateEntity.date}
                      </p>
                      <p className="text-sm">
                        Checked: {check.checked ? 'Yes' : 'No'}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default AdminDisplayTeacherAttendanceCheck;
