import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import annourLogo from '../imgs/annour-logo.png';

import SchoolServiceAdmin from '../services/SchoolServiceAdmin';
import UploadExcel from './AfterLoginPages/UploadExcel';
import CreateDeleteAbsenceListForTeacher from './AfterLoginPages/CreateDeleteAbsenceListForTeacher';
import ArchivedStudentList from './AfterLoginPages/ArchivedStudentsList';

const SchoolSuperAdmin = ({ data, setData }) => {
  const dateRef = useRef();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [allStudents, setAllStudents] = useState(null);
  const [allTeachers, setAllTeachers] = useState(null);
  const [allHolidays, setAllHolidays] = useState(null);
  const [allArchivedStudents, setAllArchivedStudents] =
    useState(null);

  const [isStudentsTableVisible, setIsStudentsTableVisible] =
    useState(false);
  const [isTeachersTableVisible, setIsTeachersTableVisible] =
    useState(false);
  const [isHolidaysTableVisible, setIsHolidaysTableVisible] =
    useState(false);

  useEffect(() => {
    if (
      !data ||
      data.roles?.filter((value) => value.roleCode === 'SUPER_ADMIN')
        .length <= 0
    ) {
      localStorage.clear();
      navigate('/');
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          students,
          archivedStudents,
          teachers,
          holidays,
          emailStatus,
        ] = await Promise.all([
          SchoolServiceAdmin.getAllStudents(),
          SchoolServiceAdmin.getAllArchivedStudents(),
          SchoolServiceAdmin.getAllTeachers(),
          SchoolServiceAdmin.getAllHolidays(),
          SchoolServiceAdmin.getEmailSendingStatus(), // New API call to get the initial email sending status
        ]);

        setAllStudents(students.data);
        setAllArchivedStudents(archivedStudents.data);
        setAllTeachers(teachers.data);
        setAllHolidays(
          holidays.data.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
        );
        setIsEmailSendingEnabled(emailStatus.data); // Set initial state based on backend
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const refreshAllTeacher = async () => {
    setLoading(true);
    try {
      const response2 = await SchoolServiceAdmin.getAllTeachers();
      setAllTeachers(response2.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const refreshAllHolidays = async () => {
    setLoading(true);
    try {
      const response2 = await SchoolServiceAdmin.getAllHolidays();
      setAllHolidays(
        response2.data.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        )
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const toggleStudentsTableVisibility = () => {
    setIsStudentsTableVisible(!isStudentsTableVisible);
  };
  const toggleTeachersTableVisibility = () => {
    setIsTeachersTableVisible(!isTeachersTableVisible);
  };
  const toggleHolidaysTableVisibility = () => {
    setIsHolidaysTableVisible(!isHolidaysTableVisible);
  };
  const createNewHolidayDate = async () => {
    try {
      const response = await SchoolServiceAdmin.createNewHoliday(
        dateRef.current.value
      );
      refreshAllHolidays();
    } catch (error) {
      console.log(error);
    }
  };

  const [isEmailSendingEnabled, setIsEmailSendingEnabled] =
    useState(false);

  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    try {
      await SchoolServiceAdmin.updateSendEmailFlag(isChecked);
      setIsEmailSendingEnabled(isChecked);
      console.log('Update successful:');
    } catch (error) {
      console.error('Error updating send email flag:', error);
    }
  };

  return (
    <div className="container mx-auto my-8">
      <ArchivedStudentList
        allArchivedStudents={allArchivedStudents}
        loading={loading}
      />
      <div className="inline-flex items-center justify-center w-full ">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-green-600"
            checked={isEmailSendingEnabled}
            onChange={handleCheckboxChange}
          />
          <span className="ml-2 text-gray-700">
            Enable Email Sending
          </span>
        </label>
      </div>
      <div className="inline-flex items-center justify-center w-full ">
        <hr className="w-96 h-1 my-8 bg-cyan-800 border-0 rounded " />
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 space-x-2">
          <button
            onClick={toggleStudentsTableVisibility}
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isStudentsTableVisible
              ? 'Hide All Students'
              : 'Show All Students'}
          </button>
          <button
            onClick={() => {
              navigate('/superadmin/createstudent');
            }}
            className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-800"
          >
            +
          </button>
        </div>
      </div>

      {!loading && isStudentsTableVisible && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">First Name</th>
                <th className="py-2 px-4">Last Name</th>
                <th className="py-2 px-4">First Name Arabic</th>
                <th className="py-2 px-4">Last Name Arabic</th>
                <th className="py-2 px-4">Parents Email</th>
                <th className="py-2 px-4">Class Days Of Week</th>
                <th className="py-2 px-4">Teacher User Name</th>
                <th className="py-2 px-4">Teacher Arabic Name</th>
              </tr>
            </thead>
            <tbody>
              {allStudents?.map((myStudent) => (
                <tr
                  className="border-b text-center"
                  key={myStudent.id}
                >
                  <td className="py-2 px-4 ">{myStudent.id}</td>
                  <td className="py-2 px-4">{myStudent.firstName}</td>
                  <td className="py-2 px-4">{myStudent.lastName}</td>
                  <td className="py-2 px-4">
                    {myStudent.firstNameArabic}
                  </td>
                  <td className="py-2 px-4">
                    {myStudent.lastNameArabic}
                  </td>
                  <td className="py-2 px-4">{myStudent.email}</td>
                  <td className="py-2 px-4">
                    {myStudent.classDaysOfWeek.map((e) => e + ', ')}
                  </td>
                  <td className="py-2 px-4">
                    {myStudent.teacherUserName}
                  </td>
                  <td className="py-2 px-4">
                    {myStudent.teacherFirstName}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <hr className="w-960 h-2  mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <div className="inline-flex items-center justify-center w-full">
        <hr className="w-96 h-1 my-8 bg-cyan-800 border-0 rounded " />
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 space-x-2">
          <button
            onClick={toggleTeachersTableVisibility}
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isTeachersTableVisible
              ? 'Hide All Teachers'
              : 'Show All Teachers'}
          </button>
          <button
            onClick={() => {
              navigate('/superadmin/createuser');
            }}
            className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-800"
          >
            +
          </button>
        </div>
      </div>

      {!loading && isTeachersTableVisible && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">Email</th>
                <th className="py-2 px-4">First Name</th>
                <th className="py-2 px-4">Last Name</th>
                <th className="py-2 px-4">User Name</th>
                <th className="py-2 px-4">Class Abbrev</th>
                <th className="py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allTeachers?.map((myTeacher) => (
                <tr
                  className="border-b text-center"
                  key={myTeacher.id}
                >
                  <td className="py-2 px-4 ">{myTeacher.id}</td>
                  <td className="py-2 px-4 ">{myTeacher.email}</td>
                  <td className="py-2 px-4">{myTeacher.firstName}</td>
                  <td className="py-2 px-4">{myTeacher.lastName}</td>
                  <td className="py-2 px-4 ">{myTeacher.username}</td>
                  <td className="py-2 px-4 ">
                    {myTeacher.classAbbrev}
                  </td>
                  <td className="py-2 px-4 space-x-2 ">
                    <button
                      onClick={() =>
                        navigate(
                          `/superadmin/updatestudentgroup/${myTeacher.id}`
                        )
                      }
                      className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
                    >
                      update student group
                    </button>
                    <button
                      onClick={async () => {
                        await SchoolServiceAdmin.deleteTeacher(
                          myTeacher.id
                        );
                        refreshAllTeacher();
                      }}
                      className="rounded bg-red-700 text-white px-6 py-2 font-semibold hover:bg-red-800"
                    >
                      delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <div className="inline-flex items-center justify-center w-full">
        <hr className="w-96 h-1 my-8 bg-cyan-800 border-0 rounded " />
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 space-x-2">
          <button
            onClick={toggleHolidaysTableVisibility}
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isHolidaysTableVisible
              ? 'Hide Holidays'
              : 'Show Holidays'}
          </button>
          <input type="date" ref={dateRef} />
          <button
            onClick={createNewHolidayDate}
            className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-800"
          >
            +
          </button>
        </div>
      </div>

      {!loading && isHolidaysTableVisible && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">Date</th>
                <th className="py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allHolidays?.map((holiday) => (
                <tr className="border-b text-center" key={holiday.id}>
                  <td className="py-2 px-4 ">{holiday.id}</td>
                  <td className="py-2 px-4 ">{holiday.date}</td>
                  <td className="py-2 px-4">
                    <button
                      onClick={async () => {
                        await SchoolServiceAdmin.deleteHoliday(
                          holiday.id
                        );
                        refreshAllHolidays();
                      }}
                      className="rounded bg-red-700 text-white px-6 py-2 font-semibold hover:bg-red-800"
                    >
                      delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
      <UploadExcel />
      <CreateDeleteAbsenceListForTeacher allTeachers={allTeachers} />
    </div>
  );
};

export default SchoolSuperAdmin;
