import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import annourLogo from '../imgs/annour-logo.png';

import SchoolServiceAdmin from '../services/SchoolServiceAdmin';
import UploadExcel from './AfterLoginPages/UploadExcel';
import { levelColors, levelTranslations } from './ConstFuncs';
import ArchivedStudentList from './AfterLoginPages/ArchivedStudentsList';
import AdminDisplayTeacherAttendanceCheck from './AfterLoginPages/AdminDisplayTeacherAttendanceCheck';

const SchoolAdmin = ({ data, setData }) => {
  const dateRef = useRef();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [allStudents, setAllStudents] = useState(null);
  const [allArchivedStudents, setAllArchivedStudents] =
    useState(null);

  const [allTeachers, setAllTeachers] = useState(null);
  const [allHolidays, setAllHolidays] = useState(null);

  const [isStudentsTableVisible, setIsStudentsTableVisible] =
    useState(false);
  const [isTeachersTableVisible, setIsTeachersTableVisible] =
    useState(false);
  const [isHolidaysTableVisible, setIsHolidaysTableVisible] =
    useState(false);

  useEffect(() => {
    if (!data) {
      localStorage.clear();
      navigate('/');
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await SchoolServiceAdmin.getAllStudents();
        setAllStudents(response.data);

        const responseAllArchivedStudents =
          await SchoolServiceAdmin.getAllArchivedStudents();
        setAllArchivedStudents(responseAllArchivedStudents.data);

        //console.log(response.data);

        const response2 = await SchoolServiceAdmin.getAllTeachers();
        setAllTeachers(response2.data);

        const response3 = await SchoolServiceAdmin.getAllHolidays();
        setAllHolidays(
          response3.data.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
        );
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const refreshAllTeacher = async () => {
    setLoading(true);
    try {
      const response2 = await SchoolServiceAdmin.getAllTeachers();
      setAllTeachers(response2.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const refreshAllHolidays = async () => {
    setLoading(true);
    try {
      const response2 = await SchoolServiceAdmin.getAllHolidays();
      setAllHolidays(
        response2.data.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        )
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const toggleStudentsTableVisibility = () => {
    setIsStudentsTableVisible(!isStudentsTableVisible);
  };
  const toggleTeachersTableVisibility = () => {
    setIsTeachersTableVisible(!isTeachersTableVisible);
  };
  const toggleHolidaysTableVisibility = () => {
    setIsHolidaysTableVisible(!isHolidaysTableVisible);
  };
  const createNewHolidayDate = async () => {
    try {
      const response = await SchoolServiceAdmin.createNewHoliday(
        dateRef.current.value
      );
      refreshAllHolidays();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mx-auto my-8">
      <ArchivedStudentList
        allArchivedStudents={allArchivedStudents}
        loading={loading}
      />
      <div className="inline-flex items-center justify-center w-full ">
        <hr className="w-96 h-1 my-8 bg-cyan-800 border-0 rounded " />
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 space-x-2">
          <button
            onClick={toggleStudentsTableVisibility}
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isStudentsTableVisible
              ? 'Hide All Students'
              : 'Show All Students'}
          </button>
        </div>
      </div>

      {!loading && isStudentsTableVisible && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden ">
            <thead className="bg-gray-800 text-white text-sm md:text-base lg:text-lg">
              <tr>
                <th className="py-2">First Name Arabic</th>
                <th className="py-2">Last Name Arabic</th>
                <th className="py-2">Parents Email</th>
                <th className="py-2">Class Days Of Week</th>
                <th className="py-2 px-4">Teacher User Name</th>
                <th className="py-2 px-4">Teacher Arabic Name</th>
                <th className="py-2">Student Level</th>
              </tr>
            </thead>
            <tbody>
              {allStudents?.map((myStudent) => (
                <tr
                  className="border-b text-center"
                  key={myStudent.id}
                >
                  <td className="py-2">
                    {myStudent.firstNameArabic}
                  </td>
                  <td className="py-2">{myStudent.lastNameArabic}</td>
                  <td className="py-2">{myStudent.email}</td>
                  <td className="py-2">
                    {myStudent.classDaysOfWeek.map((e) => e + ', ')}
                  </td>
                  <td className="py-2 px-4">
                    {myStudent.teacherUserName}
                  </td>
                  <td className="py-2 px-4">
                    {myStudent.teacherFirstName}
                  </td>
                  <td
                    className={`py-2 font-semibold text-gray-300 ${
                      myStudent.level != null
                        ? levelColors[myStudent.level]
                        : 'bg-slate-600'
                    }`}
                  >
                    {myStudent.level != null
                      ? levelTranslations[myStudent.level]
                      : 'لم يتم الاختيار بعد'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <hr className="w-960 h-2  mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <div className="inline-flex items-center justify-center w-full">
        <hr className="w-96 h-1 my-8 bg-cyan-800 border-0 rounded " />
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 space-x-2">
          <button
            onClick={toggleTeachersTableVisibility}
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isTeachersTableVisible
              ? 'Hide All Teachers'
              : 'Show All Teachers'}
          </button>
        </div>
      </div>

      {!loading && isTeachersTableVisible && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          {allTeachers?.map((myTeacher) => (
            <div
              key={myTeacher.id}
              className="w-full text-left sm:w-full md:w-full lg:w-1/2 xl:w-1/3"
            >
              <button
                onClick={() => {
                  navigate(`/admin/getstudentgroup/${myTeacher.id}`);
                }}
                className={`  bg-white border shadow-xl p-5 my-5 mx-5 md:mx-0
          flex items-center w-11/12 text-left transition ease-in-out cursor-pointer lg:hover:-translate-y-1 lg:hover:scale-110 duration-300 relative z-100`}
              >
                <div
                  className={
                    'md:pl-10 pl-3 text-cyan-800 font-semibold text-base'
                  }
                >
                  <p>ID: {myTeacher.id}</p>
                  <p>Email: {myTeacher.email}</p>
                  <p>First Name: {myTeacher.firstName}</p>
                  <p>Last Name: {myTeacher.lastName}</p>
                  <p>User Name: {myTeacher.userName}</p>
                  <p>Class Abbrev: {myTeacher.classAbbrev}</p>
                </div>
              </button>
            </div>
          ))}
        </div>
      )}
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <div className="inline-flex items-center justify-center w-full">
        <hr className="w-96 h-1 my-8 bg-cyan-800 border-0 rounded " />
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 space-x-2">
          <button
            onClick={toggleHolidaysTableVisibility}
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isHolidaysTableVisible
              ? 'Hide Holidays'
              : 'Show Holidays'}
          </button>
        </div>
      </div>

      {!loading && isHolidaysTableVisible && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">Date</th>
              </tr>
            </thead>
            <tbody>
              {allHolidays?.map((holiday) => (
                <tr className="border-b text-center" key={holiday.id}>
                  <td className="py-2 px-4 ">{holiday.id}</td>
                  <td className="py-2 px-4 ">{holiday.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <AdminDisplayTeacherAttendanceCheck />
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
      {/*!loading && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <p>LATER OVERVIEW</p>
        </div>
      )*/}
    </div>
  );
};

export default SchoolAdmin;
