import axios from 'axios';

export const getAuthToken = () => {
  return window.localStorage.getItem('auth_token');
};

export const setAuthHeader = (token) => {
  window.localStorage.setItem('auth_token', token);
};

//When you run the npm start or npm run build commands, Create React App sets NODE_ENV to 'development' or 'production', respectively.
const isProduction = process.env.NODE_ENV === 'production';

const developmentBaseURL =
  //'https://api.annour-bs.de/spring-security-demo-app-0.0.1-SNAPSHOT/api/v1';
  'http://localhost:8080/api/v1';
const productionBaseURL =
  'https://api.annour-bs.de/spring-security-demo-app-0.0.1-SNAPSHOT/api/v1';

axios.defaults.baseURL = isProduction
  ? productionBaseURL
  : developmentBaseURL;

export const request = (
  method,
  url,
  data,
  contentType = 'application/json'
) => {
  let headers = {};
  if (getAuthToken() !== null && getAuthToken() !== 'null') {
    headers = { Authorization: `Bearer ${getAuthToken()}` };
  }

  if (contentType) {
    headers['Content-Type'] = contentType;
  }

  return axios({
    method: method,
    url: url,
    headers: headers,
    data: data,
  });
};
